import styled from "styled-components";
import { media } from "styled-bootstrap-grid";

const QuoteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const QuoteInnerContainer = styled.div`
  width: 100%;
  margin: 32px 0;
  text-align: left;
`;

const Content = styled.span`
  font-size: 1.3em;
  font-style: italic;

  ${media.sm`
    font-size: 1.6em;
  `}
`;

const Author = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

const AuthorImage = styled.div`
  width: 48px;
`;

const AuthorContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const AuthorName = styled.span`
  font-size: 1.2em;
  font-weight: 600;
`;

const AuthorRole = styled.span`
  font-size: 1em;
`;

export {
  QuoteContainer,
  QuoteInnerContainer,
  Content,
  Author,
  AuthorImage,
  AuthorContent,
  AuthorName,
  AuthorRole,
};
