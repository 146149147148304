import React from "react";
import {
  SuccessStory as SuccessStoryType,
  SuccessStoryQueryResult,
} from "app-types/success-story";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import { PageContainer } from "styles/global.style";
import { Col, Container, Row } from "styled-bootstrap-grid";
import {
  ChallengeRow,
  Content,
  HeaderContainer,
  HeaderPayoff,
  HeaderSubtitle,
  LogoContainer,
  MetadataBox,
  MetadataContent,
  OtherSuccessStory,
  MetadataInnerContent,
  SeeMoreLink,
  MetadataTitle,
  OtherSuccessStoryContainer,
} from "./index.style";
import Loop from "components/core/Loop";
import SuccessStoryComponent from "components/ui/base/SuccessStory";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import Register from "components/ui/extended/RegisterForCustomerService";
import Customers from "components/ui/extended/Customers";
import { createRendererAst } from "utils/render-ast";
import Quote from "templates/success-story/quote";
import Statistic from "templates/success-story/statistic";
import SafeLink from "components/core/SafeLink";
import Icon from "components/core/Icon";

export interface SuccessStoryProps {
  pageContext: {
    successStory: SuccessStoryType;
  };
  data: SuccessStoryQueryResult;
  location: Location;
}

const SuccessStory = ({ pageContext, data, location }: SuccessStoryProps) => {
  const successStory: SuccessStoryType = pageContext.successStory;
  const otherSuccessStories = data.successStories.nodes;
  const { t } = useTranslation();
  const renderAst = createRendererAst({
    statistic: Statistic,
    quote: Quote,
  });

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={`${successStory.name} - Success Stories with Customerly`}
        withoutTitleSuffix={true}
        description={successStory.payoff}
        image={{
          imageData:
            successStory.cover.localFile.childImageSharp.gatsbyImageData,
          alt: successStory.name,
        }}
      />

      <HeaderContainer
        backgroundUrl={
          successStory.background.localFile.childImageSharp.gatsbyImageData
            .images.fallback?.src
        }
      >
        <PageContainer>
          <HeaderSubtitle level={4}>{successStory.name}</HeaderSubtitle>
          <HeaderPayoff as={"h1"}>{successStory.payoff}</HeaderPayoff>
        </PageContainer>
      </HeaderContainer>

      <PageContainer>
        <Container>
          <Row>
            <Col md={3}>
              <LogoContainer>
                <GatsbyImage
                  image={
                    successStory.logo.localFile.childImageSharp.gatsbyImageData
                  }
                  alt={successStory.name}
                />
              </LogoContainer>
              <MetadataBox>
                <MetadataTitle>
                  {t("templates.success-story.about")}
                </MetadataTitle>
                <MetadataContent>
                  <MetadataInnerContent>
                    {successStory.about}
                  </MetadataInnerContent>
                </MetadataContent>
              </MetadataBox>
              <MetadataBox>
                <MetadataTitle>
                  {t("templates.success-story.category")}
                </MetadataTitle>
                <MetadataContent>
                  <MetadataInnerContent>
                    {successStory.category}
                  </MetadataInnerContent>
                </MetadataContent>
              </MetadataBox>
              <MetadataBox>
                <MetadataTitle>
                  {t("templates.success-story.location")}
                </MetadataTitle>
                <MetadataContent>
                  <MetadataInnerContent>
                    {successStory.location}
                  </MetadataInnerContent>
                </MetadataContent>
              </MetadataBox>
              <MetadataBox>
                <MetadataTitle>
                  {t("templates.success-story.companySize")}
                </MetadataTitle>
                <MetadataContent>
                  <MetadataInnerContent>
                    {successStory.companySize}
                  </MetadataInnerContent>
                </MetadataContent>
              </MetadataBox>
              <MetadataBox>
                <MetadataTitle>
                  {t("templates.success-story.website")}
                </MetadataTitle>
                <MetadataContent>
                  <MetadataInnerContent>
                    <a href={successStory.websiteUrl} target="_blank">
                      {successStory.websiteUrl}
                    </a>
                  </MetadataInnerContent>
                </MetadataContent>
              </MetadataBox>
            </Col>
            <Col md={9}>
              <ChallengeRow>
                <Col sm={12} md={4}>
                  <MetadataTitle>
                    {t("templates.success-story.challenge")}
                  </MetadataTitle>
                  <MetadataContent>
                    <MetadataInnerContent>
                      {successStory.challenge}
                    </MetadataInnerContent>
                  </MetadataContent>
                </Col>
                <Col sm={12} md={4}>
                  <MetadataTitle>
                    {t("templates.success-story.solution")}
                  </MetadataTitle>
                  <MetadataContent>
                    <MetadataInnerContent>
                      {successStory.solution}
                    </MetadataInnerContent>
                  </MetadataContent>
                </Col>
                <Col sm={12} md={4}>
                  <MetadataTitle>
                    {t("templates.success-story.result")}
                  </MetadataTitle>
                  <MetadataContent>
                    <MetadataInnerContent>
                      {successStory.result}
                    </MetadataInnerContent>
                  </MetadataContent>
                </Col>
              </ChallengeRow>

              <Row>
                <Col>
                  <Content>
                    {renderAst(
                      successStory.content.childMarkdownRemark.htmlAst
                    )}
                  </Content>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <OtherSuccessStoryContainer>
                <OtherSuccessStory>
                  {t("templates.success-story.others")}
                </OtherSuccessStory>
                <SeeMoreLink as={SafeLink} to={"/success-stories/"}>
                  {t("templates.success-story.seeMore")}
                  <Icon type={"arrow-right"} size={16} />
                </SeeMoreLink>
              </OtherSuccessStoryContainer>
              <Row>
                <Loop items={otherSuccessStories}>
                  {(successStory: SuccessStoryType) => (
                    <Col md={6} sm={12} key={successStory.slug}>
                      <SuccessStoryComponent successStory={successStory} />
                    </Col>
                  )}
                </Loop>
              </Row>
            </Col>
          </Row>
        </Container>
      </PageContainer>

      <Register />

      <Customers />
    </GlobalWrapper>
  );
};

export default SuccessStory;

export const successStoryDetailPageQuery = graphql`
  query {
    successStories: allContentfulSuccessStory(
      sort: { fields: createdAt, order: DESC }
      limit: 2
      filter: { node_locale: { eq: "en" } }
    ) {
      nodes {
        id
        slug
        name
        category
        result
        cover {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
