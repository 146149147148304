import React from "react";
import {
  Author,
  AuthorContent,
  AuthorImage,
  AuthorName,
  AuthorRole,
  Content,
  QuoteContainer,
  QuoteInnerContainer,
} from "./quote.style";
import StaticImg from "components/core/StaticImg";

const Quote = ({ children, name, role, image }: any) => {
  return (
    <QuoteContainer>
      <QuoteInnerContainer>
        <Content>"{children}"</Content>
        <Author>
          <AuthorImage>
            <StaticImg alt={name} src={image} />
          </AuthorImage>
          <AuthorContent>
            <AuthorName>{name}</AuthorName>
            <AuthorRole>{role}</AuthorRole>
          </AuthorContent>
        </Author>
      </QuoteInnerContainer>
    </QuoteContainer>
  );
};

export default Quote;
