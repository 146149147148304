import React from "react";
import {
  StatisticContainer,
  StatisticInnerContainer,
  StatisticNumber,
} from "./statistic.style";

const Statistic = ({ pre, post, number, children }: any) => {
  return (
    <StatisticContainer>
      <StatisticInnerContainer>
        <StatisticNumber>
          {pre}
          {number}
          {post}
        </StatisticNumber>
        <br />
        {children}
      </StatisticInnerContainer>
    </StatisticContainer>
  );
};

export default Statistic;
