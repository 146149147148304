import styled from "styled-components";

const StatisticContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const StatisticInnerContainer = styled.div`
  width: 100%;
  padding: 32px;
  margin: 32px 0;
  border-radius: ${(props) => props.theme.borderRadius};
  background: #effaf1;
  text-align: center;
  color: #74c69d;
`;

const StatisticNumber = styled.span`
  color: #74c69d;
  font-size: 4em;
  line-height: 1.2;
  font-weight: 600;
`;

export { StatisticContainer, StatisticInnerContainer, StatisticNumber };
